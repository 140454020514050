import React from 'react'
import { styled } from 'gatsby-theme-stitches/src/config'

import useSetSeedDesign from '../hooks/useSetSeedDesign'
import { Seo } from '../components/Seo'
import ErrorPage from './404'

const HomeTemplate = () => {
  useSetSeedDesign()
  return (
    <Container>
      <Seo />
      <ErrorPage />
    </Container>
  )
}

const Container = styled('main', {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export default HomeTemplate
